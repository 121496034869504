export enum EQueryParam {
  Auth = 'auth',
  SamlSsoCode = 'code',
  SamlSsoSessionState = 'session_state',
  Search = 'search',
  CategoryId = 'categoryId',
  Sort = 'sort',
  Page = 'page',
  Filter = 'filter',
  Type = 'type',
  OrderNumber = 'orderNumber',
}
