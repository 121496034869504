export function formatPrice(
  price: number,
  isShowFractionPartForInt = false,
  fixFractionDigits = 2,
  sign = '₽',
  disabledRounding = false,
  hideSign = false,
): string {
  const isInteger = Number.isInteger(price);
  const integerPart = Number(Math.trunc(price)).toLocaleString('ru');
  const fractionalPart = !isInteger || isShowFractionPartForInt
    ? disabledRounding
      ? Number(price).toString().split('.')?.[1] ?? ''
      : Number(price).toFixed(fixFractionDigits).split('.')?.[1] ?? ''
    : undefined;

  const priceSign = !hideSign ? ` ${sign}` : '';

  return !isInteger || isShowFractionPartForInt
    ? `${integerPart}${fractionalPart ? ',' + fractionalPart : ''}${priceSign}`
    : `${integerPart}${priceSign}`;
}
