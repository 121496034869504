export enum ERuLocales {
  Car = 'car',
  Apple = 'apple',
  MarketplaceQuantity = 'marketplaceQuantity',
  MarketplaceQuantityNumber = 'marketplaceQuantityNumber',
  ItemsQuantity = 'itemsQuantity',
  Item = 'item',
  Year = 'year',
  Month = 'month',
  Day = 'day',
  DayAlternate = 'dayAlternate',
  Products = 'products',
  Models = 'models',
  ParentCaseProducts = 'products',
  FinalsCategories = 'finalsCategories',
  Categories = 'categories',
  Supplier = 'Supplier',
  Hour = 'hour',
  Minute = 'minute',
  SelectedCategories = 'selectedCategories',
  SelectedBrands = 'selectedBrands',
  Addresses = 'addresses',
  SelectAdresses = 'selectAddresses',
  ToClients = 'toClients',
  ToBasis = 'toBasis',
  AddToCart = 'addToCart',
  Offer = 'offer',
  NotificationsUnreadCount = 'notificationsUnreadCount',
  ItemPosition = 'itemPosition',
  Source = 'source',
  UpToDays = 'upTopDays',
  City = 'city',
  Region = 'region',
  NewMasculine = 'newMasculine',
  NewNeuter = 'newNeuter',
  ActiveMasculine = 'activeMasculine',
  Values = 'values',
  CargoSpace = 'cargoSpace',
  CargoSpaceLong = 'cargoSpaceLong',
  Orders = 'orders',
  Suborders = 'suborders',
  UnitsNoCount = 'unitsNoCount',
  ProductPositions = 'productPositions',
  FromSupplier = 'fromSupplier',
  SupplierOffer = 'supplierOffer',
  Lists = 'lists',
  Needs = 'needs',
  Decimals = 'decimals',
  FromOrders = 'fromOrders',
}
